import styled from 'styled-components'
import { media } from '../../styles/media'

export const PricingContainer = styled.div`
    padding: 100px 0;
    background: #f2f2f2;

    ${media.maxMd`
        padding: 50px 0;
    `}
`

export const Title = styled.h1`
    font-size: 60px;
    letter-spacing: 2px;
    margin: 0 auto 50px 100px;
    text-transform: uppercase;
    color: #000;
    text-align: center;

    ${media.maxMd`
        font-size: 40px;
        margin: 0 20px 50px 20px;
    `}
`

export const Divider = styled.hr`
    width: 90%;
    background-color: #f2f2f2;
    border-top: 15px dotted black;
    margin-bottom: 150px;
    margin-top: 50px;

    ${media.maxMd`
        width: 80%;
        margin-top: 75px;
    `}
`