import styled from 'styled-components'

import { Button } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Footer = styled.footer`
    padding: 15px 0;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`

export const Copyright = styled.h2`
    font-size: 1.4rem;
    letter-spacing: 2px;
    font-weight: normal;
    text-align: center;
`

export const SocialMediasWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
`

export const SocialMediaButton = styled.a`
    margin: 0;
    padding: 0;

    &:hover {
        background: none;
        cursor: pointer;
    }
`

export const SocialMediaIcon = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 15px;
`

