import { PropsWithChildren } from "react";
import * as S from "./styled";

type PricingItem = {
  id: string;
  title: string;
  descriptionList: string[];
};

const pricingList: PricingItem[] = [
  {
    id: "1",
    title: "Basic",
    descriptionList: [
      "Single-page",
      "Pro malé firmy, OSVČ i jednotlivce",
      "Méně informací, ale velmi jednoduché",
    ],
  },
  {
    id: "2",
    title: "Standard",
    descriptionList: [
      "Obvykle 2 - 4 stránky",
      "Vhodné pro menší firmy a OSVČ",
      "Pro mnoho uživatelů/klientů nejpřehlednější",
    ],
  },
  {
    id: "3",
    title: "Premium",
    descriptionList: [
      "Obvykle 5 a více stránek",
      "Vhodné pro firmy všech velikostí",
      "Velmi přehledné, velké množství informací",
    ],
  },
];

type Props = PropsWithChildren<{
  title: string;
  descriptionList: string[];
}>;

const PriceItem: React.FC<Props> = ({ title, descriptionList }) => {
  return (
    <S.PriceItem>
      <S.PriceItemHeader>
        <S.PriceItemTitle>{title}</S.PriceItemTitle>
      </S.PriceItemHeader>
      <S.DescriptionList>
        {descriptionList.map((description, index) => (
          <S.DescriptionText key={index}>{description}</S.DescriptionText>
        ))}
      </S.DescriptionList>
    </S.PriceItem>
  );
};

const PriceList = () => {
  return (
    <S.PriceList>
      {pricingList.map((item) => (
        <PriceItem
          key={item.id}
          title={item.title}
          descriptionList={item.descriptionList}
        />
      ))}
    </S.PriceList>
  );
};

export default PriceList;
