import { useState } from 'react'
import { Box } from '@mui/material'

import { publicImages } from '../publicImages'

import * as S from './styled'

type NavigationItem = {
    id: string
    label: string
    link: string
}

const navItems: NavigationItem[] = [
    {
        id: '1',
        label: 'Home',
        link: '#home'
    },
    {
        id: '2',
        label: 'Proč BeeStyle?',
        link: '#about'
    },
    {
        id: '3',
        label: 'Průběh spolupráce',
        link: '#timeline'
    },
    {
        id: '4',
        label: 'Ceník',
        link: '#pricing'
    },
    {
        id: '5',
        label: 'Kontakt',
        link: '#contact'
    },
]

const Header = () => {
    const [ mobileMenuOpened, setMobileMenuOpened ] = useState(false)

    return (
        <S.Header opened={mobileMenuOpened}>
            <S.HeaderSpacer>
                <Box sx={{ display: 'flex', m: 0, p: 0, alignItems: 'flex-end' }}>
                    <S.LogoMenuHeader>
                        <S.LogoWrapper>
                            <S.DesktopLogo src={publicImages.logoWithText} alt='beestyle logo' />
                            <S.MobileLogo src={publicImages.simpleLogo} alt='mobile beestyle logo' />
                        </S.LogoWrapper>
                        <S.MenuButton onClick={() => setMobileMenuOpened(!mobileMenuOpened)} aria-label='open and close menu icon'>
                            {mobileMenuOpened ? <S.CloseMenuIcon sx={{ color: 'white', fontSize: '60px' }} /> : <S.MenuToggleIcon sx={{ color: 'white', fontSize: '60px' }} />}
                        </S.MenuButton>
                    </S.LogoMenuHeader>

                    <S.HeaderLinks>
                        <S.DesktopMenuContainer>
                            {navItems.map((item) => (
                                <li key={item.id}>
                                    <S.MenuLink href={item.link}>{item.label}</S.MenuLink>
                                </li>
                            ))}
                        </S.DesktopMenuContainer>
                    </S.HeaderLinks>
                </Box>
            </S.HeaderSpacer>

            <S.OpenedMobileMenuWrapper opened={mobileMenuOpened}>
                <Box sx={{ display: 'flex', m: 0, p: 0, alignItems: 'center', flexDirection: 'column' }}>
                    <S.Navigation>
                        <S.MobileMenuList>
                            {navItems.map((item) => (
                                <S.MobileMenuItem key={item.id}>
                                    <S.MobileMenuLink href={item.link} onClick={() => setMobileMenuOpened(false)}>
                                        {item.label}
                                    </S.MobileMenuLink>
                                </S.MobileMenuItem>
                            ))}
                        </S.MobileMenuList>
                    </S.Navigation>
                </Box>
            </S.OpenedMobileMenuWrapper>
        </S.Header>
    )
}

export default Header