import { publicImages } from '../publicImages'
import * as S from './styled'

const Footer = () => {
    return (
        <S.Footer>
            <S.Copyright>© 2023 Patrik Pluhař</S.Copyright>
            <S.SocialMediasWrapper>
                <S.SocialMediaButton href='https://www.linkedin.com/in/patrik-pluhar/' target="_blank" >
                    <S.SocialMediaIcon src={publicImages.linkedin} />
                </S.SocialMediaButton>
                <S.SocialMediaButton href='https://www.instagram.com/beestyle.web/' target="_blank" >
                    <S.SocialMediaIcon src={publicImages.instagram} /> 
                </S.SocialMediaButton>
                <S.SocialMediaButton href='https://www.facebook.com/beestyleweb' target="_blank" >
                    <S.SocialMediaIcon src={publicImages.facebook} />
                </S.SocialMediaButton>
            </S.SocialMediasWrapper>
        </S.Footer>
    )
}

export default Footer