import React, { useState } from 'react'

import InfoBanner from './InfoBanner'

import * as S from './styled'

type InfoBoxProps = {
    success: boolean
    show: boolean
}

const ContactContainer = () => {
    const [ isEmailValid, setIsEmailValid ] = useState(true)
    const [ isMessageValid, setIsMessageValid ] = useState(true)

    const [ emailValue, setEmailValue ] = useState('')
    const [ phoneValue, setPhoneValue ] = useState('')
    const [ messageValue, setMessageValue ] = useState('')

    const [ showLoader, setShowLoader ] = useState(false)
    const [ infoBoxShown, setInfoBoxShown ] = useState<InfoBoxProps>({
        success: true,
        show: false
    })


    const validateEmail = () => {
        const emailExpression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        setIsEmailValid(emailExpression.test(emailValue))
    }

    const validateMessage = () => {
        setIsMessageValid(messageValue?.trim() !== '')
    }

    const onSubmitForm = async (event: React.FormEvent) => {
        event.preventDefault()
        setInfoBoxShown({
            success: true,
            show: false
        })
        setShowLoader(true)
        
        if (!isEmailValid || !isMessageValid) {
            return
        }

        const body = {
            'email': emailValue,
            'phone': phoneValue ?? '',
            'message': messageValue
        }

        const response = await fetch('sendcontactform.php', {
            method: 'POST',
            body: JSON.stringify(body),
        })

        type Result = {
            result: string
        }

        const result: Result = await response.json();

        setShowLoader(false)
        if (result.result === '1') {
            setInfoBoxShown({
                success: true,
                show: true
            })

            setEmailValue('')
            setPhoneValue('')
            setMessageValue('')
        } else {
            setInfoBoxShown({
                success: false,
                show: true
            })
        }
    }

    return (
        <S.ContactContainer id='contact'>
            <S.ContactContentBox>
                <S.ContactInfoBox>
                    <S.Title>Kontakt</S.Title>
                    <S.Text><S.Bold>Patrik Pluhař</S.Bold></S.Text>
                    <S.Text>+420 608 762 023</S.Text>
                    <S.Text>beestyleweb@gmail.com</S.Text>
                    <S.Text>IČO: 17487587</S.Text>
                    <S.Text><S.Italic>Nejsem plátcem DPH</S.Italic></S.Text>
                </S.ContactInfoBox>
                <S.ContactFormBox>
                    <InfoBanner values={infoBoxShown} />
                    <S.LoaderWrapper display={showLoader}>
                        <S.Loader />
                    </S.LoaderWrapper>
                    <form onSubmit={(event) => onSubmitForm(event)}>
                        <S.CustomTextField required label="E-mail" variant="outlined" value={emailValue} sx={{ minWidth: '300px' }} valid={isEmailValid} onBlur={validateEmail} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmailValue(event.target.value)} />
                        <S.CustomTextField label="Telefon (nepovinné)" variant="outlined" value={phoneValue} margin='normal' valid={true} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneValue(event.target.value)} />
                        <S.CustomTextField required label="Zpráva" variant="outlined" value={messageValue} multiline rows={6} margin='normal' valid={isMessageValid} onBlur={validateMessage} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMessageValue(event.target.value)}  />
                        <S.SubmitButton type='submit'></S.SubmitButton>
                    </form>
                </S.ContactFormBox>
            </S.ContactContentBox>
        </S.ContactContainer>
    )
}

export default ContactContainer