export const publicImages = {
    mainPicture: 'images/main_picture2.png',
    simpleLogo: 'images/beestyle_logo.png',
    logoWithText: 'images/beestyle_logo_text.png',
    webdesigner: 'images/webdesigner.png',
    independence: 'images/independence.png',
    idea: 'images/idea.png',
    price: 'images/price.png',
    quality: 'images/quality.png',
    instagram: 'images/instagram.svg',
    facebook: 'images/facebook.svg',
    linkedin: 'images/linkedin.svg'
}