import styled from 'styled-components'
import { media } from '../../styles/media'

export const AboutContainer = styled.div`
    background-color: #fff;
    color: #000;
    width: 100%;
    padding-top: 100px;
    min-height: 100vh;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    ${media.maxLg`
        align-items: center;
    `}
`

export const Title = styled.h1`
    font-size: 60px;
    letter-spacing: 2px;
    margin: 0 auto 50px 100px;
    text-transform: uppercase;

    ${media.maxMd`
        font-size: 40px;
        margin: 0 20px 50px 30px;
    `}
`

export const WebdesignerPicture = styled.img`
    width: 761px;
    height: 501px;

    ${media.maxLg`
        width: 600px;
        height: 395px;
    `}

    ${media.maxMd`
        display: none;
    `}
`

export const WhyBeeStyleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 100px;

    ${media.maxMd`
        margin-left: 50px;
        margin-right: 50px;
    `}
`