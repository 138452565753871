import styled from 'styled-components'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

import { publicImages } from '../../publicImages'
import { media } from '../../styles/media'

export const WelcomeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    background-image: url(${publicImages.mainPicture});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 30%;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background-color: #000;

    ${media.md`
        background-position: center;
    `}

    ${media.maxMd`
        background-size: 100%;
    `}
`

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.maxMd`
        margin-left: 5%;
        margin-right: 5%;
    `}
`

export const MainTitle = styled.h1`
    color: white;
    font-size: 6rem;
    letter-spacing: 15px;
    margin: 0;


    ${media.maxLg`
        font-size: 5rem;
        letter-spacing: 12px;
    `}

    ${media.maxMd`
        font-size: 4rem;
        letter-spacing: 8px;
    `}

    ${media.maxSm`
        font-size: 2.5rem;
        letter-spacing: 5px;
    `}
`

export const WelcomeTextContainer = styled.div`
    font-size: 1.5rem;
    letter-spacing: 1.1px;
`

export const WelcomeText = styled.p`
    margin: 0;
    text-align: center;
`

export const MyDiv = styled.div`
    height: 300px;
    width: 100%;
`

export const ArrowDownButton = styled.a`
    border: none;
    margin: 50px 0;

    ${media.md`
        &:hover {
            transform: scale(1.5);
            transition-duration: 1.5s;
        }
    `}
`

export const ArrowDown = styled(ArrowBackIosNewIcon)`
    transform: rotate(-90deg);
`