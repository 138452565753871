import React from 'react'
import { publicImages } from '../../publicImages'
import BeeStyleReasonItem from './BeeStyleReasonItem'

import * as S from './styled'

type Item = {
    id: string
    image: string
    alt: string
    title: string
    text: string
}

const beeStyleReasonItems: Item[] = [
    {
        id: '1',
        image: publicImages.independence,
        alt: 'independence',
        title: 'Nezávislost',
        text: 'Jsem na volné noze (OSVČ) a svým klientům tak věnuji maximální pozornost. Přijímám jen tolik zakázek, abych byl schopen zpracovat webové stránky co nejrychleji a v nejvyšší kvalitě!'
    },
    {
        id: '2',
        image: publicImages.idea,
        alt: 'idea',
        title: 'Řešení na míru',
        text: 'Potřebujete firemní nebo soukromé webové stránky? Žádný problém! Navrhnu a implementuji řešení přímo Vám na míru. Před vpuštěním do světa projde řešení Vaší kontrolou. Věci, které se Vám nebudou líbit zdarma upravím. Stránky programuji a styluji zcela sám bez využití externích šablon jako WordPress. Ručím za nejvyšší kvalitu a nejsem tak ničím limitován.'
    },
    {
        id: '3',
        image: publicImages.quality,
        alt: 'quality',
        title: 'Kvalita',
        text: 'Garantuji, že výsledkem budou kvalitní a responzivní webové stránky, ke kterým bude radost přistupovat jak z počítače, tak i z tabletu nebo mobilního telefonu.'
    },
    {
        id: '4',
        image: publicImages.price,
        alt: 'price',
        title: 'Cena',
        text: 'Nejsem agentura ani velká firma. Oproti konkurenci tak dokážu zpracovat stejně kvalitní řešení za mnohem nižší cenu.'
    }
]

const AboutContainer = () => {
    return (
        <S.AboutContainer id='about'>
            <S.Title>Proč BeeStyle?</S.Title>
            <S.ContentWrapper>
                <S.WebdesignerPicture src={publicImages.webdesigner} alt='webdesigner' />
                <S.WhyBeeStyleWrapper>
                    {beeStyleReasonItems.map((item) => (
                        <BeeStyleReasonItem key={item.id} imageSrc={item.image} alt={item.alt} title={item.title}>
                            {item.text}
                        </BeeStyleReasonItem>
                    
                    ))}
                </S.WhyBeeStyleWrapper>
            </S.ContentWrapper>
        </S.AboutContainer>
    )
}

export default AboutContainer