import React, { PropsWithChildren } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Menu } from '@mui/icons-material';

import * as S from './styled'

type TimelineItem = {
    id: string
    date: string
    title: string
    text: string
    icon: React.ReactNode
    theme: 'light' | 'dark'
}

const timelineElements: TimelineItem[] = [
    {
        id: '1',
        date: '0. den',
        title: 'Kontaktujete mě',
        text: 'Nejprve mě kontaktujete telefonicky, emailem nebo prostřednictvím formuláře dole. Domluvíme si hovor, popř. osobní setkání (možné v Olomouci a okolí, popř. jinde dle domluvy)',
        icon: <Menu />,
        theme: 'dark'
    },
    {
        id: '2',
        date: 'Do 1. týdne',
        title: 'Pobavíme se o Vaších požadavcích',
        text: 'Přes telefon nebo osobně se pobavíme o Vaších představách a požadavcích a společně vytvoříme zadání Vám na míru. Vše Vám vysvětlím a zároveň určím orientační cenu a délku práce.',
        icon: <Menu />,
        theme: 'light'
    },
    {
        id: '3',
        date: '2 - 4 týdny',
        title: 'Práce na webových stránkách',
        text: 'Následující 2 až 4 týdny mi zabere práce na Vaších webových stránkách. Jakmile bude hotovo, kontaktuji Vás a dohodneme si ukázku. Následně zpracuji případné drobné úpravy, které jsou zdarma.',
        icon: <Menu />,
        theme: 'dark'
    },
    {
        id: '4',
        date: 'Několik hodin',
        title: 'Zveřejnění stránek',
        text: 'Nyní jsou Vaše stránky připraveny ke zveřejnění. Buď to mohu udělat já, anebo Vám předám pouze zdrojové kódy a zveřejnění bude ve Vaší režii.',
        icon: <Menu />,
        theme: 'light'
    },
    {
        id: '5',
        date: '...',
        title: 'Další spolupráce',
        text: 'Zveřejněním stránek ovšem naše spolupráce končit nemusí. Mám vlastní server, kam mohu webové stránky nasadit za výhodnou cenu, čímž Vám odpadne starost s hostingem. Popřípadě se v budoucnu můžeme dohodnout na dalších úpravách nebo rozšířeních.',
        icon: <Menu />,
        theme: 'dark'
    }
]

type TimelineElementProps = PropsWithChildren<{
    date: string
    title: string
    icon: React.ReactNode
    theme: 'light' | 'dark'
}>

const TimelineElement: React.FC<TimelineElementProps> = ({ date, title, icon, theme, children }) => {
    return theme === 'dark' ? (
    <VerticalTimelineElement
        contentStyle={{ background: '#424242', color: '#fff', border: 'none' }}
        contentArrowStyle={{ borderRight: '7px solid #424242' }}
        iconStyle={{ background: '#424242', color: '#fff' }}
        icon={icon}
        >
        <h3 className="vertical-timeline-element-title">{date}</h3>
        <S.TimelineDate className="vertical-timeline-element-subtitle">{title}</S.TimelineDate>
        <S.TextContent>
            {children}
        </S.TextContent>
    </VerticalTimelineElement>
    ) : (
    <VerticalTimelineElement
        contentStyle={{ background: '#fff', color: '#000', border: 'none' }}
        contentArrowStyle={{ borderRight: '7px solid #fff' }}
        iconStyle={{ background: '#a8a8a8', color: '#000' }}
        icon={icon}
        >
        <h3 className="vertical-timeline-element-title">{date}</h3>
        <S.TimelineDate className="vertical-timeline-element-subtitle">{title}</S.TimelineDate>
        <S.TextContent>
            {children}
        </S.TextContent>
    </VerticalTimelineElement>
    )
}

const TimelineContainer = () => {
    return (
    <S.TimelineContainer id='timeline'>
        <S.Title>Průběh spolupráce</S.Title>
        <VerticalTimeline lineColor='#000'>
            {timelineElements.map((element) => (
                <TimelineElement key={element.id} date={element.date} title={element.title} icon={element.icon} theme={element.theme}>
                    {element.text}
                </TimelineElement>
            ))}
        </VerticalTimeline>
    </S.TimelineContainer>
    )
}

export default TimelineContainer