import React from 'react'
import ReactDOM from 'react-dom/client'
import Footer from './Footer'
import Header from './Header'
import MainPage from './MainPage'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { StyledEngineProvider } from '@mui/material/styles'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <Header />
      <MainPage />
      <Footer />
    </StyledEngineProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
