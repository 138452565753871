import AboutContainer from './AboutContainer';
import ContactContainer from './ContactContainer/';
import PricingContainer from './PricingContainer';
import TimelineContainer from './TimelineContainer';
import WelcomeContainer from './WelcomeContainer';

import * as S from './styled'

const MainPage = () => {
  return (
    <S.MainPage>
      <WelcomeContainer />
      <AboutContainer />
      <TimelineContainer />
      <PricingContainer />
      <ContactContainer />
    </S.MainPage>
  );
}

export default MainPage
