import { grey } from '@mui/material/colors';

import * as S from './styled'

const WelcomeContainer = () => {
    return (
    <S.WelcomeContainer id='home'>
        <S.TextContainer>
          <S.MainTitle>BeeStyle</S.MainTitle>
          <S.WelcomeTextContainer>
            <S.WelcomeText>Profesionální webové stránky na míru pro firmy i podnikatele</S.WelcomeText>
          </S.WelcomeTextContainer>
          <S.ArrowDownButton href='#about' aria-label='arrow down button'>
            <S.ArrowDown sx={{ fontSize: '100px', color: grey[50] }} />
          </S.ArrowDownButton>
        </S.TextContainer>
    </S.WelcomeContainer>
    )
}

export default WelcomeContainer;