import styled from 'styled-components'
import { media } from '../../../styles/media'

export const PriceList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
`

export const PriceItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(#000428, #004e92);
    border-radius: 30px;
    padding: 30px;
    margin: 30px 0;

    ${media.maxMd`
        margin: 30px 20px;
    `}
`

export const PriceItemHeader = styled.div`
    border-bottom: dotted 10px #fff;
    width: 90%;
    text-align: center;
`

export const PriceItemTitle = styled.h2`
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 5px;
`

export const PriceText = styled.h3`
    font-size: 1.8rem;
    letter-spacing: 1.3px;
    margin-top: 5px;
`

export const DescriptionList = styled.ul`
    text-align: left;
    margin-top: 30px;
`

export const DescriptionText = styled.li`
    font-size: 1.2rem;
`