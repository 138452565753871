import styled from 'styled-components'
import { media } from '../../styles/media'

export const TimelineContainer = styled.div`
    background-color: #f2f2f2;
    padding: 100px 0;

    ${media.maxMd`
        padding-bottom: 0;
    `}
`

export const Title = styled.h1`
    font-size: 60px;
    letter-spacing: 2px;
    margin-bottom: 50px;
    text-align: center;
    color: #000;
    text-transform: uppercase;

    ${media.maxMd`
        font-size: 40px;
        margin: 0 20px 50px 20px;
    `}
`

export const TimelineDate = styled.h3`
    font-size: 1.6rem !important;
`

export const TextContent = styled.p`
    font-size: 1.3rem !important;
    line-height: 1.4 !important;
`