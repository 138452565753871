import styled from 'styled-components'
import { media } from '../../../styles/media'

export const BeeStyleReasonItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 0;
    margin-bottom: 30px;

    ${media.maxMd`
        flex-direction: column;
    `}

    ${media.md`
        &:hover {
            h2 {
                text-decoration: underline;
            }
        }
    `}
`

export const BeeStyleReasonItemHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const Icon = styled.img`
    width: 70px;
    height: 70px;
`

export const DesktopTitle = styled.h2`
    margin: 0;

    ${media.maxMd`
        display: none;
    `}
`

export const ResponsiveTitle = styled.h2`
    margin-left: 30px;
    ${media.md`
        display: none;
    `}
`

export const BeeStyleReasonItemContent = styled.div`
    margin-left: 30px;

    ${media.maxMd`
        margin-left: 0;
        margin-top: 20px;
    `}
`

export const Text = styled.p`
    font-size: 1.3rem;
    margin-top: 10px;
`