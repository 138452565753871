import styled from 'styled-components'

import { CircularProgress, TextField } from '@mui/material'
import { media } from '../../styles/media'


export const ContactContainer = styled.div`
    background-color: #fff;
    color: #000;
    padding: 75px 0;
`

export const Title = styled.h1`
    font-size: 60px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 50px;

    ${media.maxMd`
        font-size: 40px;
        margin-bottom: 20px;
    `}
`

export const ContactContentBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    margin-left: 30px;
    margin-right: 30px;

    ${media.maxMd`
        flex-direction: column;
    `}
`

export const ContactInfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const Bold = styled.span`
    font-weight: bold;
`

export const Italic = styled.span`
    font-style: italic;
`

export const Text = styled.p`
    font-size: 1.6rem;
    margin: 7px 0;
`

export const ContactFormBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;

    ${media.maxMd`
        margin: 20px 0;
    `}
`

export const CustomTextField = styled(TextField)<{ valid: boolean }>`
    width: 100%;
    fieldset {
        ${({ valid }) => !valid ? `border-color: red;` : ''}
    }
`

export const SubmitButton = styled.input`
    width: 200px;
    height: 50px;
    border-radius: 0px;
    border-color: black;
    font-family: Rajdhani;
    font-size: 25px;
    font-weight: bold;
    color: black;
    background-color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 10px;

    ${media.md`
        &:hover {
            color: white;
            background-color: black;
            transition: 0.7s;
            cursor: pointer;
        }
    `}

    ${media.maxMd`
        color: white;
        background-color: black;
    `}
`

export const LoaderWrapper = styled.div<{ display: boolean }>`
    ${({ display }) => display ? '' : 'display: none;'}
    position: relative;
    width: 100%;
    height: 90px;
`

export const Loader = styled(CircularProgress)`
    position: absolute;
    top: 0;
    left: 50%;
`