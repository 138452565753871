import React, { PropsWithChildren } from 'react'

import * as S from './styled'

type Props = PropsWithChildren<{
    imageSrc: string
    alt: string
    title: string
}>

const BeeStyleReasonItem: React.FC<Props> = ({ imageSrc, alt, title, children }) => {
    return (
        <S.BeeStyleReasonItem>
            <S.BeeStyleReasonItemHeader>
                <S.Icon src={imageSrc} alt={alt} />
                <S.ResponsiveTitle>{title}</S.ResponsiveTitle>
            </S.BeeStyleReasonItemHeader>
            <S.BeeStyleReasonItemContent>
                <S.DesktopTitle>{title}</S.DesktopTitle>
                <S.Text>{children}</S.Text>
            </S.BeeStyleReasonItemContent>
        </S.BeeStyleReasonItem>
    )
}

export default BeeStyleReasonItem