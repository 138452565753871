import { css, CSSObject } from 'styled-components'

const breakpoints = {
    xs: 0,
    sm: 600,
    md: 1130,
    lg: 1280,
    xl: 1920,
    xxl: 2560,
}

const mediaQuery =
  (
    first: TemplateStringsArray | CSSObject,
    ...query: Array<TemplateStringsArray | CSSObject | number>
  ) =>
  (...rules: Parameters<typeof css>) =>
    css`
      @media ${css(first, ...query)} {
        ${css(...rules)}
      }
    `

// helper functions to generate media queries for any size bigger than:
const media = {
  xxl: mediaQuery`(min-width: ${breakpoints.xxl}px)`,
  xl: mediaQuery`(min-width: ${breakpoints.xl}px)`,
  lg: mediaQuery`(min-width: ${breakpoints.lg}px)`,
  md: mediaQuery`(min-width: ${breakpoints.md}px)`,
  sm: mediaQuery`(min-width: ${breakpoints.sm}px)`,
  xs: mediaQuery`(min-width: ${breakpoints.xs}px)`,
  maxSm: mediaQuery`(max-width: ${breakpoints.sm - 1}px)`,
  maxMd: mediaQuery`(max-width: ${breakpoints.md - 1}px)`,
  maxLg: mediaQuery`(max-width: ${breakpoints.lg - 1}px)`,
  maxXlg: mediaQuery`(max-width: ${breakpoints.xl - 1}px)`,
  maxXXlg: mediaQuery`(max-width: ${breakpoints.xxl - 1}px)`,
  maxMdLandscape: mediaQuery`screen and (orientation:landscape) and (max-width: ${
    breakpoints.sm - 1
  }px)`,
}

export { media }