import PriceList from './PriceList'
import * as S from './styled'

const PricingContainer = () => {
    return (
        <S.PricingContainer id='pricing'>
            <S.Divider />
            <S.Title>Orientační ceník</S.Title>
            <PriceList />
        </S.PricingContainer>
    )
}

export default PricingContainer